<template>
    <v-data-table fixed-header :items-per-page=-1 hide-default-footer single-select sort-by="timeStamp"
        :sort-desc="true" :height="kioskAnalyticsDimension.qnaAnalyticsheight" :loading="loading"
        :headers="analysisHeader" :items="analysisList" item-key="id">
        <template v-slot:item.result.action="{ item }">
            {{ item.result.action | trimLength }}
        </template>
        <template v-slot:item.answer="{ item }">
            {{ item.answer | trimLength }}
        </template>
        <template v-slot:item.timeStamp="{ item }">
            {{ item.timeStamp | formatDateFromNow }}
        </template>
        <template v-slot:item.confidenceScore="{ item }">
            <v-chip dark small :color="getColor(item.confidenceScore)" @click="getAnswerObject(item)">
                <span>
                    {{ item.confidenceScore }}
                </span>
            </v-chip>
            <!-- <v-chip dark small :color="getColor(item.result.answers[0].score)">
                {{ item.result.answers[0].score }}
            </v-chip> -->
        </template>
    </v-data-table>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex';

export default {
    name: 'qnaAnalytics',
    props: {
        analysisList: Array,
        loading: Boolean,
    },
    data() {
        return {
            expanded: [],
            analysisHeader: [
                { text: 'Question Asked', align: 'start', value: 'result.action' },
                { text: 'Top Response Answer', value: 'answer' },
                { text: 'Confidence Score', align: '', value: 'confidenceScore' },
                { text: 'TimeStamp', value: 'timeStamp' },
            ],
        }
    },
    computed: {
        ...mapGetters(['kioskAnalyticsDimension']),
        getRandomNumber() {
            return Math.random() < 0.7
                ? Math.floor(Math.random() * (100 - 85 + 1)) + 85  // 70% chance to be between 85-100
                : Math.floor(Math.random() * (85 - 45 + 1)) + 45;
        }
    },
    methods: {
        getColor(score) {
            if (score * 100 >= 76) {
                return 'success';
            }
            else if (score * 100 >= 60) {
                return 'amber';
            }
            else {
                return 'error';
            }
        },
    },
    filters: {
        formatDate(val) {
            if (!val) { return '-' }
            return moment(val).format('L')
        },
        formatDuration(val) {
            if (!val) { return '-' }
            return moment(val).format('mm:ss')
        },
        formatTime(val) {
            if (!val) { return '-' }
            return moment(val).format('LT')
        },
        formatStringTime(val) {
            if (!val) { return '-' }
            return moment(val).format('LTS')
        },
        formatDateFromNow(val) {
            if (!val) { return '-' }
            return moment(val).fromNow();
        },
        trimLength(val) {
            if (typeof val !== 'string') return val
            if (val === null) return val
            if (val === '') return val
            if (val.length <= 100) { return val }
            return `${val.substring(0, 50)}...`
        }
    },
}
</script>
